<template>
  <Header />
  <section class="spacing__x f-header">
    <img class="prop prop-top-left" src="@/assets/images/elements/element-3.png" alt="" />
    <img class="prop prop-middle-left" src="@/assets/images/elements/element-4-1.png" alt="" />
    <img class="prop prop-middle-right" src="@/assets/images/elements/element-5.png" alt="" />
    <img class="prop prop-bottom-right" src="@/assets/images/elements/element-4.png" alt="" />
    <img class="prop prop-bottom-left" src="@/assets/images/elements/element-2.png" alt="" />
    <div class="container">
      <div class="cart-detail-section formButton mb-40 d-flex justify-content-between">
        <h1 class="fs-30 color-1 mb-10 flex-1 text-sm-center">
          {{ $store.getters["bookingForm/activityForBooking"].title }}
        </h1>
        <div class="row justify-content-end flex-grow-1 flex-shrink-0 align-items-center gap-10 mb-15">
          <a class="c-btn bg-color-tertiary br-10 text-shadow" href="#">Continue Shopping</a>
          <template v-if="!$store.getters['auth/isAuth']">
            <hr class="vr" />
            <router-link to="/login" class="color-1">
              <i class="fa fa-lock"></i>
              Login
            </router-link>
          </template>
        </div>
      </div>
      <div class="form__wrap leadForm">
        <h4 class="section__heading color-white text-center py-4">
          Please Fill In The Details
        </h4>
        <form @submit.prevent="formSubmit">
          <div class="input__wrap">
            <div class="form--label-wrap">
              <label for="partyDate">Party Date:</label>
            </div>
            <input type="date" id="partyDate" v-model="bookingForm.partyDate" @blur="v$.bookingForm.partyDate.$touch"
              @change="onDateChange($event.target.value)" />
            <div class="input-errors" v-for="(error, index) of v$.bookingForm.partyDate.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="input__wrap">
            <div class="form--label-wrap">
              <label>Available Slots</label>
            </div>
            <div>
              <div v-for="(rooms, roomIndex) in roomCollection" :key="roomIndex">
                <h6>{{rooms[0].room.title}}</h6>
                <ul class="nav nav-pills nav-stacked">
                <li v-for="(slot, slotIndex) in rooms" :key="index" @click="handleItemClick(roomIndex, slotIndex, slot)"
                  :class="{ 'active': activeSlot === `${roomIndex}-${slotIndex}` }">
                  <a class="btn btn-primary" :class="{ 'not-allowed': slot.is_occupied }" :disabled="slot.is_occupied"
                    @blur="v$.bookingForm.partyTime.$touch">{{ slot.start_time }} - {{ slot.end_time }}</a>
                </li>
              </ul>
              </div>
            </div>
            <div class="input-errors" v-for="(error, index) of v$.bookingForm.partyTime.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="input__wrap">
            <div class="form--label-wrap">
              <label for="childName">Child Name:</label>
              <p>Name of the birthday child.</p>
            </div>
            <input type="text" id="childName" v-model="bookingForm.childName" @blur="v$.bookingForm.childName.$touch" />
            <div class="input-errors" v-for="(error, index) of v$.bookingForm.childName.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="input__wrap">
            <div class="form--label-wrap">
              <label for="parentName">Parent/Guardian's Name:</label>
              <p>Name of the Parent/Guardian of birthday child.</p>
            </div>
            <input type="text" id="parentName" v-model="bookingForm.parentName"
              @blur="v$.bookingForm.parentName.$touch" />
            <div class="input-errors" v-for="(error, index) of v$.bookingForm.parentName.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="input__wrap">
            <div class="form--label-wrap">
              <label for="SocksTab">Socks tab:</label>
              <p>
                Will you (Party Host) pay for the grip socks cost for your party
                guests? $2.99 + HST per pair.
              </p>
            </div>
            <select name="SocksTab" id="SocksTab" v-model="bookingForm.payForPartyGuest"
              @blur="v$.bookingForm.payForPartyGuest.$touch">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <div class="input-errors" v-for="(error, index) of v$.bookingForm.payForPartyGuest.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="input__wrap">
            <div class="form--label-wrap">
              <label for="CakeType">Cake Type:</label>
              <p>
               Select Cake Type.
              </p>
            </div>
            <select name="CakeType" id="CakeType" v-model="bookingForm.cakeType"
              @blur="v$.bookingForm.cakeType.$touch">
              <option value="Vanilla">Vanilla</option>
              <option value="Chocolate">Chocolate</option>
              <option value="Half Vanilla /Half Chocolate">Half Vanilla /Half Chocolate</option>
              <option value="No Cake">No Cake</option>
            </select>
            <div class="input-errors" v-for="(error, index) of v$.bookingForm.cakeType.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="input__wrap" v-if="isCakeIncluded">
            <div class="form--label-wrap">
              <label for="writingOnCake">Writing On Cake:</label>
              <p>Writing On Cake</p>
            </div>
            <input type="text" id="writingOnCake" v-model="bookingForm.writingOnCake"
              @blur="v$.bookingForm.writingOnCake.$touch" />
            <div class="input-errors" v-for="(error, index) of v$.bookingForm.writingOnCake.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="input__wrap" v-if="isCakeIncluded">
            <div class="form--label-wrap">
              <label for="IcingColor">Icing Color:</label>
              <p>
                Write Icing Color On Cake.
              </p>
            </div>
            <input type="text" id="IcingColor" v-model="bookingForm.icingColor" @blur="v$.bookingForm.icingColor.$touch" />
            <div class="input-errors" v-for="(error, index) of v$.bookingForm.icingColor.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="input__wrap" v-for="pizzaNo in pizzaOptionCount" :key="pizzaNo">
            <div class="form--label-wrap">
              <label for="item_1">Pizza {{ pizzaNo }}:</label>
              <p>Select pizza Topping.</p>
            </div>
            <div>
              <select name="item_1" id="item_1" v-model="bookingForm.pizza[`pizza_${pizzaNo}`]"
                @change="onPizzaChange($event.target.value, pizzaNo)" @blur="onPizzaBlur(pizzaNo)">
                <option value="Large Cheese Pizza">
                  Large Cheese Pizza
                </option>
                <option value="Large Veggie Pizza">
                  Large Veggie Pizza
                </option>
                <option value="Large Pepperoni Pizza">
                  Large Pepperoni Pizza
                </option>
              </select>
              <div class="input-errors" v-if="isShowErrorMessage(pizzaNo)">
                <div class="error-msg">Field is Required</div>
              </div>
            </div>
          </div>
          <button type="submit" class="c-btn style-4" :disabled="isFormValid()" :class="{ 'not-allowed': isFormValid() }">
            SAVE INFORMATION
          </button>
        </form>
      </div>
    </div>
  </section>
  <Footer />
</template>
<script>
import Header from "./layouts/Header.vue";
import Footer from "./layouts/Footer.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import { AttractionType } from '../enums/index';
import { pizzaPackagesDetails } from '../utility/constants';
import { Party } from '../apiAgent';
import { redirectScreen } from '../utility/helpers';
import _ from 'lodash';
export default {
  name: "BookingForm",
  components: {
    Header,
    Footer,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      bookingForm: {
        partyDate: "",
        partyTime: "",
        childName: "",
        parentName: "",
        payForPartyGuest: "",
        pizza: {},
        cake: "",
        writingOnCake: "",
        cakeType : "",
        icingColor: ""
      },
      pizzaOptionCount: 0,
      pizzaSelectionError: {},
      availableSlots: [],
      activeSlot: null,
      roomCollection : [],
      roomId: null,
      noCakeType : 'No Cake',
      isCakeIncluded : true
    }
  },
  watch: {
    'bookingForm.cakeType': {
      handler: function (after, before) {
        this.isCakeIncluded = (after === this.noCakeType) ? false : true;
      },
      deep: true
    }
  },
  mounted() {
    this.createDynamicPizza();
    const partyAttractionInCart = this.$store.getters['cart/Cart'].find(x => x.attractionType === AttractionType.Party);
    if (partyAttractionInCart && partyAttractionInCart.attractions.length > 0) {
      //Check either party in cart is same, that is selected on listing.
      const selectedParty = this.$store.getters["bookingForm/activityForBooking"];
      //If its same than repopulate the form.
      if (partyAttractionInCart.attractions[0].attractionId == selectedParty?.id) {
        const partyDetail = partyAttractionInCart.attractions[0].meta.party_reservation;
        const _package = this.findPackage();
        let _pizza = {};
        if (_package) {
          for (let i = 1; i <= _package.pizza; i++) {
            _pizza[`pizza_${i}`] = partyDetail[`pizza_${i}`];
          }
          this.bookingForm = {
            partyDate: partyDetail.partyDate,
            partyTime: partyDetail.partyTime,
            childName: partyDetail.childName,
            parentName: partyDetail.parentName,
            payForPartyGuest: partyDetail.payForPartyGuest,
            cake: partyDetail.cake,
            writingOnCake: partyDetail.writingOnCake,
            cakeType : partyDetail.cakeType,
            icingColor : partyDetail.icingColor,
            pizza: _pizza
          };
          this.availableSlots = partyAttractionInCart.partyTimeSlots;
          let index = 0;
          for (const timeSlot of this.availableSlots) {
            if (timeSlot.start_time === this.bookingForm.partyTime) {
              this.activeSlot = index;
            }
            index++;
          }
        }
      }
    }
  },
  methods: {
    redirectScreen,
    formSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          this.$store.dispatch("loader/setLoader", true);
          const bookingDetails = this.$store.getters["bookingForm/activityForBooking"];
          //NOTE: Create cloned object for booking form, to manipulate pizza.
          let o = structuredClone(this.bookingForm);
          for (const i of Object.entries(o.pizza)) {
            o[i[0]] = i[1];
          }
          o.isCakeIncluded = this.isCakeIncluded;
          delete o.pizza;
          let totalPayment = bookingDetails.price;
          const cartObject = {
            attractionType: AttractionType.Party,
            booking_date: this.bookingForm.partyDate,
            booking_time: this.bookingForm.partyTime,
            attractions: [{
              attractionId: bookingDetails.id,
              attractionName: bookingDetails.title,
              singlePrice: 0.0,
              totalPayment : totalPayment,
              quantity: 1,
              discount: {
                amount: bookingDetails.discount_value ? parseFloat(bookingDetails.discount_value) : 0.00,
                type: bookingDetails.discount_type
              },
              meta: {
                booking_source: "Website",
                party_reservation: o,
              },
              isCakeIncluded : this.isCakeIncluded
            }],
            partyTimeSlots : this.availableSlots
          };
          //Delete the previously added party object from cart.
          const partyAttractionInCart = this.$store.getters['cart/Cart'].find(x => x.attractionType === AttractionType.Party);
          if (partyAttractionInCart && partyAttractionInCart.attractions.length > 0) {
            this.$store.dispatch('cart/DeleteSpecificAttraction', AttractionType.Party);
          }
          let clonedCartObject = _.cloneDeep(cartObject);
          const advancePaymentPrice = (totalPayment * parseFloat(process.env.VUE_APP_BOOKING_ADVANCE)) / 100;
          clonedCartObject.attractions[0].singlePrice = advancePaymentPrice;
          clonedCartObject.attractions[0].roomId = this.roomId;
          //Set Cart Details.         
          this.$store.dispatch('cart/SaveCart', clonedCartObject);
          this.redirectScreen();
          setTimeout(() => {
            this.$store.dispatch("loader/setLoader", false);
          }, 500);
        } catch (error) {
          console.error('error: ', error);
          this.$store.dispatch("loader/setLoader", false);
        }
      }
    },
    onPizzaChange(value, id) {
      this.pizzaSelectionValidation(id);
      this.bookingForm.pizza[`pizza_${id}`] = value;
    },
    onPizzaBlur(id) {
      this.pizzaSelectionValidation(id);
    },
    pizzaSelectionValidation(id) {
      this.pizzaSelectionError[id] = this.bookingForm.pizza[`pizza_${id}`] === '' ? true : false;
    },
    isShowErrorMessage(id) {
      const isError = this.pizzaSelectionError[id] ? true : false;
      return isError;
    },
    isFormValid() {
      const isPizzaValid = Object.values(this.bookingForm.pizza).every(val => val !== '');
      const isValid = (!isPizzaValid || this.v$.bookingForm.$invalid) ? true : false;
      return isValid;
    },
    createDynamicPizza() {
      const _package = this.findPackage();
      if (_package) {
        this.pizzaOptionCount = _package.pizza;
        this.bookingForm.cake = _package.cake;
        for (let i = 1; i <= _package.pizza; i++) {
          this.bookingForm.pizza[`pizza_${i}`] = "";
        }
      }
    },
    findPackage() {
      const booking = this.$store.getters["bookingForm/activityForBooking"];
      const _package = pizzaPackagesDetails.find(x => x.slug === booking.slug);
      if (_package) {
        return _package;
      }
    },
    dateFormatterForNextWeek() {
      const today = new Date();
      today.setDate(today.getDate() + 7);
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');
      return {
        inStringFormat: `${day}/${month}/${year}`,
        inDateFormat: new Date(`${year}-${month}-${day}`)
      };
    },
    onDateChange(value) {
      this.availableSlots = [];
      this.bookingForm.partyTime = '';
      this.activeSlot = null;
      this.v$.$validate();
      const partyDateError = this.v$.bookingForm.partyDate.$errors.length;
      if (partyDateError === 0) {
        this.$store.dispatch("loader/setLoader", true);
        Party.availableSlot({ booking_date: value })
          .then((response) => {
            this.$store.dispatch("loader/setLoader", false);
            this.availableSlots = response.data.slots;           
            if(Object.keys(response.data.groupedSlots).length > 0){
              this.roomCollection = Object.values(response.data.groupedSlots);
            }
            if (this.availableSlots.length === 0 || this.availableSlots.every(x => x.slot.is_occupied)) {
              this.$iziToast.info({
                title: 'Info',
                message: 'There are no available slots for this date, please select another date.'
              });
            }
          })
          .catch(() => {
            this.$store.dispatch("loader/setLoader", false);
          });
      }
    },
    handleItemClick(roomIndex, slotIndex, slot) {
      if (!slot.is_occupied) {
        this.activeSlot = `${roomIndex}-${slotIndex}`;
        this.bookingForm.partyTime = slot.start_time;
        this.roomId = slot.room.id;
      }
    }
  },
  validations() {
    {
      return {
        bookingForm: {
          partyDate: {
            required,
            valid_Date: {
              $validator: function () {
                if (this.bookingForm.partyDate !== '') {
                  const afterWeekDate = this.dateFormatterForNextWeek().inDateFormat.getTime();
                  const partyDate = new Date(this.bookingForm.partyDate).getTime();
                  const user = this.$store.getters['auth/userDetails'];
                  if ((afterWeekDate <= partyDate) || user?.id === 1)
                    return true;
                  return false;
                }
                return false;
              },
              $message: `Please select a date that is at least one week ahead (${this.dateFormatterForNextWeek().inStringFormat} or later), otherwise call frontdesk 548-889-3504`
            }
          },
          partyTime: {
            required: helpers.withMessage("Time slot is required.", required),
          },
          childName: {
            required,
          },
          parentName: {
            required,
          },
          payForPartyGuest: {
            required,
          },
          cakeType: {
            required : helpers.withMessage("Please Provide Cake Type.", required)
          },
          writingOnCake: {
            required: requiredIf(function () {
              return this.isCakeIncluded;
            })
          },
          icingColor: {
            required: helpers.withMessage(
              'Please Provide Color For Icing Of Cake.',
              requiredIf(function () {
                return this.isCakeIncluded;
              })
            )
          }
        },
      };
    }
  }
};
</script>
<style scoped>
.error-msg {
  color: red !important;
  font-size: 12px;
  font-weight: bold;
}

.nav-stacked li {
  padding: 10px;
}

.nav-stacked li a {
  color: white;
}

.nav-stacked .active {
  background-color: #f0c24b;
}
</style>
