<template>
    <Header componentName="parties"></Header>
    <section class="parties-banner style-1">
        <img class="bg-header-star"
             src="@/assets/images/parties/bg-header-star.png"
             alt="">
        <div class="container text-center position-relative">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <h1 class="fs-60 tt-capitalize mb-20">Make This Summer Unforgettable at Kids Fun Hut!</h1>
                    <p class="mb-0">Give your child a summer full of learning, laughter, and new friendships!</p>
                </div>
            </div>
        </div>
    </section>

    <section class="parties-text-section theme-color spacing__x over-hidden">
        <div class="container position-relative">
            <img class="element element-star"
                 src="@/assets/images/elements/star-element.png"
                 alt="">
            <img class="element element-2"
                 src="@/assets/images/elements/element-2.png"
                 alt="">
            <div class="row align-items-center mb-40">
                <div class="col-lg-6">
                    <img src="@/assets/images/summer-camp/p-1.jpg"
                         alt=""
                         class="br-50">
                </div>
                <div class="col-lg-6">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">NO STRESS!</span>
                        <h4 class="mb-20 heading-color-1 fs-30 tt-capitalize">NO WORRIES, JUST SUMMERTIME FUN!
                        </h4>
                        <p class="regular-color-1">
                            Planning a summer camp experience for your child shouldn’t be stressful. At Kids Fun Hut, we
                            take care of everything so you can sit back, relax, and enjoy the smiles and laughter.
                            Whether you're looking to book a fun-filled day or an entire camp experience, just let us
                            know your preferred dates and the number of kids joining; we’ll handle the rest. Your only
                            job is to enjoy the adventure with them!
                        </p>
                    </div>
                </div>
            </div>

        </div>
        <div class="container position-relative">
            <img class="element star-element-2"
                 src="@/assets/images/elements/star-element-2.png"
                 alt="">
            <img class="element element-5"
                 src="@/assets/images/elements/element-5.png"
                 alt="">
            <div class="row align-items-center mb-40">
                <div class="col-lg-6">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                            SPECIAL
                        </span>
                        <h4 class="mb-20 heading-color-1 fs-30 tt-capitalize">
                            CAMP AREAS
                        </h4>
                        <p class="regular-color-1">Organising a summer camp for kids can be overwhelming, but not with
                            us! Our dedicated camp spaces ensure a lively, safe, and interactive environment where kids
                            can explore, play and learn. Our trained staff treats every event as their own, providing a
                            seamless and exciting experience. Do you have specific activities in mind? Just let us know,
                            and we’ll customise the camp to match your expectations.</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="@/assets/images/summer-camp/p-2.jpg"
                         alt=""
                         class="br-50">
                </div>
            </div>

        </div>
        <div class="container position-relative">
            <img class="element element-4"
                 src="@/assets/images/elements/element-4.png"
                 alt="">
            <img class="element element-3"
                 src="@/assets/images/elements/element-3.png"
                 alt="">
            <div class="row align-items-center mb-40">
                <div class="col-lg-6">
                    <img src="@/assets/images/summer-camp/p-3.jpg"
                         alt=""
                         class="br-50">
                </div>
                <div class="col-lg-6">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                            UNLIMITED
                        </span>
                        <h4 class="mb-20 heading-color-1 fs-30 tt-capitalize">
                            FUN and LEARNING
                        </h4>
                        <p class="regular-color-1">This year, Kids Fun Hut is more than just a summer camp! It’s a place
                            where you can enjoy fun games, exciting crafts, cool projects, and interesting experiments.
                            While you play, you'll also learn new things and build your strength and skills, all in a
                            way that fits how you learn best.</p>
                        <ul class="summer-camp-list">
                            <li>Science Experiments.</li>
                            <li>Treasure Hunting</li>
                            <li>Fun Exercising programs</li>
                            <li>Nutritional facts</li>
                            <li>Personality Development</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="spacing__x-150 barrier-birthday-section">
        <div class="container position-relative">
            <img class="element star-element-2"
                 src="@/assets/images/elements/star-element-2.png"
                 alt="">
            <img class="element star-element-3"
                 src="@/assets/images/elements/star-element-3.png"
                 alt="">
            <img class="element star-element-4"
                 src="@/assets/images/elements/star-element-4.png"
                 alt="">

            <div class="row align-items-center">
                <div class="col-lg-6">
                    <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                        EASY BOOKING
                    </span>
                    <h4 class="mb-20 heading-color-1 fs-30 tt-capitalize">
                        JUST LET US KNOW A WEEK BEFORE
                    </h4>
                    <p class="regular-color-1 mb-20">
                        Planning on short notice? No problem! Unlike traditional summer camps that require months of
                        planning, we offer hassle-free bookings with just a 7-day notice. Whether it’s a single-day camp
                        or a full-week adventure, we’ll have everything set up and ready to go.
                        <br>
                        <br>
                        Summer Camp Duration: July 1st, 2025 – August 31st, 2025.
                        <br>
                        Monday to Friday, from 8:45 AM to 4:30 PM.
                    </p>
                </div>
                <div class="col-lg-6">
                    <img src="@/assets/images/summer-camp/p-4.jpg"
                         alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- Summer Camp packages -->
    <section class="spacing__x pt-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">Packages</span>
                        <h4 class="mb-10 heading-color-1 fs-40 tt-capitalize">Summer Camp Packages</h4>
                        <p class="heading-color-1 mb-10 fs-20">
                            Kids aged 4 to 10, come join our awesome crew for a summer full of fun and adventure!
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3">
                    <div class="package-box style1">
                        <div class="price">
                            <h4 class="fs-40 w-100 text-center position-relative z-index-1">Wonder Camper <br />$69.99
                            </h4>
                        </div>
                        <div class="price-content text-center">

                            <h6 class="fs-30">Single Day</h6>
                            <img src="@/assets/images/summer-camp/sm-pack-1.jpg" />

                            <a href="tel:5488893504"
                               class="c-btn mt-3">Book Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="package-box style2">
                        <div class="price">
                            <h4 class="fs-40 w-100 text-center position-relative z-index-1">Adventure Camper <br>$194.99
                            </h4>
                        </div>
                        <div class="price-content text-center">

                            <h6 class="fs-30">3 days a week</h6>
                            <img src="@/assets/images/summer-camp/sm-pack-2.jpg" />

                            <a href="tel:5488893504"
                               class="c-btn mt-3">Book Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="package-box style1">
                        <div class="price">
                            <h4 class="fs-40 w-100 text-center position-relative z-index-1">Hero Camper <br />$299.99
                            </h4>
                        </div>
                        <div class="price-content text-center">

                            <h6 class="fs-30">5 days a week</h6>
                            <img src="@/assets/images/summer-camp/sm-pack-3.jpg" />

                            <a href="tel:5488893504"
                               class="c-btn mt-3">Book Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="summer-camp-bt-text text-center">
                        <p>A yummy snack in the morning, a delicious lunch, and another tasty snack in the evening are
                            on
                            us!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Footer></Footer>
</template>

<script>
import Header from './layouts/Header.vue';
import Footer from './layouts/Footer.vue';
import { Activities } from '../apiAgent';
// import { SlugEnum } from '../enums/index';
export default {
    name: 'SummerCamp',
    components: {
        Header,
        Footer
    },
    data: () => ({
        summerCamps: {},
        // partyEnums: { ...SlugEnum }
    }),
    mounted() {
        // this.getTags();
    },
    methods: {
        getTags() {
            this.$store.dispatch('loader/setLoader', true);
            Activities.GetTags()
                .then(({ data }) => {
                    const summerCamp = data.data.find(x => x.slug === SlugEnum.SummerCamp);
                    if (summerCamp) {
                        for (const _summerCamp of summerCamp.active_activities) {
                            this.summerCamps[`${_summerCamp.slug}`] = _summerCamp.price;
                        }
                    }
                    this.$store.dispatch('loader/setLoader', false);
                })
                .catch((error) => {
                    this.$store.dispatch('loader/setLoader', false);
                    console.error(error);
                });
        }
    }
}
</script>